











































import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator'

@Component({
  name: 'StatusColumn'
})

export default class extends Vue {
  @Prop({ default: false }) private isOutgoing!: boolean
}
