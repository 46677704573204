


































































































































































































































import {
  Component,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator'
import {
  approveCollaboration,
  cancelCollaboration,
  deleteCollaboration,
  deleteCollaborationBulk,
  exportCollaborations,
  getCollaborations,
  getCollaborationsCount,
  rejectCollaboration
} from '@/api/collaborations'
import {
  addOfficeFilters, addUserFilters,
  createFilter,
  dateFilter,
  getSortItems,
  personalFilter,
  prepareListFilters,
  singleSelectFilter,
  SortOrder
} from '@/utils/filter'
import Pagination from '@/components/Pagination/index.vue'
import ListToolbar from '@/components/ListToolbar/index.vue'
import FiltersDrawer from '@/components/Filter/FiltersDrawer.vue'
import StatusColumn from '@/views/collaboration/components/StatusColumn.vue'
import RejectionDialog from '@/components/RejectionDialog/index.vue'
import {
  debounce,
  parseTime,
  getTranslations,
  hasPermission,
  confirmDialog,
  successMsg,
  errorMsg
} from '@/utils'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'CollaborationList',
  components: {
    Pagination,
    ListToolbar,
    FiltersDrawer,
    StatusColumn,
    RejectionDialog
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isOutgoing!: boolean
  private count = 0
  private total = 0
  private list = []
  private listLoading = true
  private showFilters = false
  private search = ''
  private selected = []
  private listQuery = createFilter()
  private debounceMethod = debounce(this.getList)
  private parseTime = parseTime
  private getTranslations = getTranslations
  private hasPermission = hasPermission
  private rejectionDialog = false
  private collaboration: any = null
  private searchFields: any = [{
    key: 'property.full_code'
  }, {
    key: 'id'
  }]

  get title() {
    return this.$t('collaborationList.' + (this.isOutgoing ? 'outgoingList' : 'incomingList'))
  }

  private filters: any = [
    singleSelectFilter({
      lowerCase: true,
      label: 'filters.status',
      key: 'status',
      transKey: 'rec_status',
      list: ['APPROVED', 'REJECTED', 'NEWLY_ADDED', 'CANCELED']
    })
  ]

  addOffice(val: string) {
    const { filter, search } = addOfficeFilters(val)
    this.filters.push(filter)
    this.searchFields.push(search)
  }

  addUser(val: string) {
    const { filter, search } = addUserFilters(val)
    this.filters.push(filter)
    this.searchFields.push(search)
  }

  addPersonalFilter(val: string) {
    this.filters.push(personalFilter({
      key: `user_id_${val}`,
      label: 'filters.personal'
    }))
  }

  created() {
    this.getQueryParams()
    this.getList()
    this.addOffice(this.isOutgoing ? 'to' : 'from')
    this.addUser(this.isOutgoing ? 'to' : 'from')
    if (hasPermission(['LIST_OTHERS_COLLABORATIONS'])) {
      this.addUser(this.isOutgoing ? 'from' : 'to')
      this.addPersonalFilter(this.isOutgoing ? 'from' : 'to')
    }
  }

  private getQueryParams() {
    const query: any = this.$route.query || {}
    if (query.id) {
      this.search = query.id
    }
    this.filters.push(dateFilter({
      from: query.from ? new Date(Number(query.from)) : null,
      to: query.to ? new Date(Number(query.to)) : null
    }))
  }

  private async deleteItem(id: string) {
    const [data] = await confirmDialog('actions.apiDelete')
    if (!data) return
    try {
      await deleteCollaboration({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async cancelItem(id: string) {
    const [data] = await confirmDialog('collaborationDetail.apiCancel')
    if (!data) return
    try {
      await cancelCollaboration({ id })
      await successMsg('actions.apiDeleteSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleSelectionChange(selected = []) {
    this.selected = selected
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private async deleteItemsBulk() {
    if (!this.selected.length) {
      return errorMsg('actions.selectItems')
    }
    const [data] = await confirmDialog('actions.apiDeleteBulk')
    if (!data) return
    try {
      await deleteCollaborationBulk(this.selected.map((item: any) => {
        return item.id
      }))
      await successMsg('actions.apiDeleteBulkSuccess')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async reject() {
    try {
      await rejectCollaboration(this.collaboration)
      await successMsg('actions.apiSuccessSave')
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private isSuperAdmin(user: any) {
    return user.roles.some((role: any) => {
      return role.name === 'SUPER_ADMIN'
    })
  }

  private prepareFilters(filters: any) {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      defaultSort: [{
        field: 'cooperationDate',
        order: SortOrder.desc
      }],
      search: this.search,
      searchFields: this.searchFields,
      filters: filters,
      callback: () => {
        this.listQuery.options = {}
        if (!this.isSuperAdmin(UserModule)) {
          if (this.isOutgoing) {
            this.listQuery.options.outgoing_only = true
          } else {
            this.listQuery.options.incoming_only = true
          }
        }
      }
    })
  }

  private async filtersChanged(filters: any) {
    try {
      this.prepareFilters(filters)
      const { data } = await getCollaborationsCount(this.listQuery)
      this.count = data || 0
    } catch (err) {}
  }

  private submitFilters(filters: any) {
    this.filters = filters
    this.restartPaging()
    this.getList()
  }

  private redirectToEdit(id: any) {
    return this.$router.push('/collaboration/edit/' + id)
  }

  private handleCommand(command: string) {
    switch (command) {
      case 'download': {
        exportCollaborations(this.selected)
        break
      }
      case 'delete': {
        this.deleteItemsBulk()
        break
      }
      case 'filters': {
        this.count = this.total
        this.showFilters = true
        break
      }
    }
  }

  private rejectCollaborationAction(id: string) {
    this.handleRejectionDialog({ id }, true)
  }

  private handleRejectionDialog(item: any = null, dialog = false) {
    this.collaboration = item
    this.rejectionDialog = dialog
  }

  private async acceptCollaboration(id: string) {
    const [valid] = await confirmDialog('collaborationList.accept')
    if (!valid) return
    try {
      await approveCollaboration({ id })
      await successMsg('actions.apiSuccessSave')
      this.handleRejectionDialog()
      await this.getList()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private restartPaging() {
    if (this.listQuery.query?.pagination) {
      this.listQuery.query.pagination.page = 1
    }
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters(this.filters)
      const { data } = await getCollaborations(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
      this.count = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }

  @Watch('search')
  onSearchChanged(search: string) {
    this.search = search
    this.restartPaging()
    this.debounceMethod()
  }
}
