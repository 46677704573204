



































import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator'
import { AppModule, DeviceType } from '@/store/modules/app'
import { errorMsg, validateForm } from '@/utils'
import { Form } from 'element-ui'

@Component({
  name: 'RejectionDialog'
})

export default class extends Vue {
  @Prop({ default: false }) private showDialog!: boolean
  @Prop({ default: false }) private item!: any

  private rules = {
    rejection_reason: [{
      required: true,
      message: this.$t('form.isRequired'),
      trigger: 'blur'
    }]
  }

  get dialogSize() {
    if (AppModule.device === DeviceType.Desktop) {
      return '30%'
    }
    return '80%'
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async confirm() {
    const [data] = await validateForm(this.$refs.form as Form)
    if (!data) return errorMsg('form.formErrors')
    try {
      this.$emit('reject')
      this.handleDialog()
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private handleDialog() {
    this.$emit('close')
  }
}
