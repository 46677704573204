import request from '@/utils/request'
import { exportJson2Excel, prepareExcelHeaders } from '@/utils/excel'
import { formatJson, getTranslations } from '@/utils'

export const getCollaborations = (data: any) =>
  request({
    method: 'post',
    url: '/api/collaborations/getCollaborations',
    data
  })

export const getCollaborationsCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/collaborations/getCollaborationsCount',
    data
  })

export const getCollaboration = (params: any) =>
  request({
    method: 'get',
    url: '/api/collaborations/getCollaboration',
    params
  })

export const saveCollaboration = (data: any) =>
  request({
    method: 'post',
    url: '/api/collaborations/saveCollaboration',
    data
  })

export const saveCollaborationBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/collaborations/saveCollaborationBulk',
    data
  })

export const deleteCollaboration = (params: any) =>
  request({
    method: 'get',
    url: '/api/collaborations/deleteCollaboration',
    params
  })

export const deleteCollaborationBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/collaborations/deleteCollaborationBulk',
    data
  })

export const approveCollaboration = (params: any) =>
  request({
    method: 'get',
    url: '/api/collaborations/approveCollaboration',
    params
  })

export const cancelCollaboration = (params: any) =>
  request({
    method: 'get',
    url: '/api/collaborations/cancelCollaboration',
    params
  })

export const rejectCollaboration = (data: any) =>
  request({
    method: 'post',
    url: '/api/collaborations/rejectCollaboration',
    data
  })

export const exportCollaborations = (collaborations: any) => {
  const fields = [
    {
      key: 'property',
      type: 'custom',
      parse: (item: any) => {
        if (item.property) {
          return item.property.full_code
        }
        return ''
      }
    },
    {
      key: 'user_from',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.user_from)
      }
    },
    {
      key: 'user_to',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.user_to)
      }
    },
    {
      key: 'office_from',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.office_from, ['name'])
      }
    },
    {
      key: 'office_to',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.office_to, ['name'])
      }
    },
    {
      key: 'client',
      type: 'custom',
      parse: (item: any) => {
        return getTranslations(item.client)
      }
    },
    {
      key: 'property',
      type: 'custom',
      parse: (item: any) => {
        if (item.property) {
          return item.property.full_code
        }
        return ''
      }
    },
    {
      key: 'notes'
    },
    {
      key: 'date_created',
      type: 'timestamp'
    }
  ]
  return exportJson2Excel(
    prepareExcelHeaders(fields),
    formatJson(fields, collaborations),
    'collaboration-list'
  )
}
